<template>
  <div class="wizard-container">
    <div class="card card-wizard active" id="wizardProfile">
      <form @submit.prevent>
        <!--
          You can switch " data-color="primary" "  with one of the next bright colors: "green", "orange", "red", "blue"
        -->
        <div class="card-header text-center">
          <slot name="header">
            <h3 v-if="title" class="card-title">{{ title }}</h3>
            <h5 v-if="subTitle" class="description">{{ subTitle }}</h5>
          </slot>

          <div class="wizard-navigation">
            <div class="progress-with-circle">
              <div class="progress-bar" role="progressbar" aria-valuenow="1" aria-valuemin="1" aria-valuemax="3"
                :style="{ width: `${progress}%` }"></div>
            </div>
            <ul class="nav nav-pills">
              <li v-for="(tab, index) in tabs" :key="tab.title" role="tab" :tabindex="tab.checked ? 0 : ''"
                :id="`step-${tab.tabId}`" :aria-controls="tab.tabId" :aria-disabled="!tab.active"
                :aria-selected="tab.active" :ref="`tab-${index}`" class="nav-item wizard-tab-link" :style="linkWidth">
                <a class="nav-link" @click="navigateToTab(index)" :class="[
                  { 'disabled-wizard-link': !tab.checked },
                  { active: tab.active },
                  { checked: tab.checked },
                ]" data-toggle="tab" style="padding-bottom: 8px">
                  <tab-item-content :tab="tab"></tab-item-content>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="card-body">
          <div class="tab-content">
            <slot :activeIndex="activeTabIndex" :activeTab="activeTab"> </slot>
          </div>
        </div>

        <div class="card-footer">
          <slot name="footer" :next-tab="nextTab" :prev-tab="prevTab">
            <div class="pull-right">
              <base-button v-if="activeTabIndex == 2" wide type="primary" @click.native="refresh">
                {{ endButtonText }}
              </base-button>
            </div>
            <div class="pull-right" :title="$store.getters.getSnUpdate == null ? $t('update.selectormessage') : ''">
              <base-button :disabled="select_disabled" v-if="activeTabIndex < tabCount - 2" wide @click.native="nextTab"
                class="btn-next">
                {{ nextButtonText }}
              </base-button>
              <base-button :disabled="button_disabled" v-else-if="activeTabIndex == 1" wide
                @click.native="startUpdate">{{ finishButtonText }}</base-button>
            </div>

            <div class="pull-left">
              <base-button v-if="activeTabIndex > 0 && activeTabIndex < 2" wide @click="cancel" class="btn-previous">
                {{ prevButtonText }}
              </base-button>
            </div>
            <div class="clearfix"></div>
            <div v-if="updateVisibility === 'visible'" style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              ">
              <SvgComponent style="height: 200px; width: 220px" />
              <base-progress label="" value-position="center" :animated="true" :value="page !== -1 ? page : null"
                style="width: 220px" />
            </div>
            <div v-else-if="
              localStorageData &&
              localStorageData.updateStarted === 'waiting' &&
              activeTabIndex == 1
            " style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              ">
              <SvgComponent2 style="height: 50px; width: 50px" />
              <div style="margin-top: 10px; text-align: center" v-html="$t('update.waiting')"></div>
            </div>
          </slot>
        </div>
      </form>
    </div>
    <div class="card card-wizard active" id="wizardProfile">
      <form @submit.prevent>

        <div class="card-body  text-center">
          <h3 class="card-title">{{ $t('update.cuttentVersions') }}</h3>
        </div>

        <div class="card-footer">
          <el-table header-cell-class-name="table-transparent" header-row-class-name="table-transparent"
            row-class-name="table-transparent" :data="tableData">
            <el-table-column min-width="120" :label="$t('update.SerialNumber')" property="SN"></el-table-column>
            <el-table-column min-width="90" label="Master" property="master"></el-table-column>
            <el-table-column min-width="90" label="Gateway" property="gateway"></el-table-column>
          </el-table>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { throttle } from "./throttle";
import Swal from "sweetalert2/dist/sweetalert2.js";
import SvgComponent from "src/assets/images/update.svg";
import SvgComponent2 from "src/assets/images/spinner.svg";
import { mapGetters } from "vuex";
import { BaseProgress } from "src/components/index";
import { Table, TableColumn } from 'element-ui';
export default {
  name: "simple-wizard",
  props: {
    startIndex: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "Title",
    },
    subTitle: {
      type: String,
      default: "Subtitle",
    },
    prevButtonText: {
      type: String,
      default: "Previous",
    },
    nextButtonText: {
      type: String,
      default: "Next",
    },
    finishButtonText: {
      type: String,
      default: "Start",
    },
    endButtonText: {
      type: String,
      default: "End",
    },
    vertical: {
      type: Boolean,
    },
  },

  components: {
    SvgComponent,
    SvgComponent2,
    BaseProgress,
    TabItemContent: {
      functional: true,
      props: ["tab"],
      render(h, { props }) {
        let content = props.tab.$slots.label;
        if (content && content.length) {
          return content;
        }
        return h("span", [props.tab.$slots.label, props.tab.label]);
      },
    },
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab,
    };
  },
  data() {
    return {
      tabs: [],
      activeTabIndex: 0,
      tabLinkWidth: 0,
      tabLinkHeight: 50,
      selectedSn: null,
      localStorageData: JSON.parse(localStorage.getItem("updateStarted")),
      page: 0,
      updates_count: 0,
      dataInterval: undefined,
    };
  },
  computed: {
    tabCount() {
      return this.tabs.length;
    },
    linkWidth() {
      let width = 100;
      if (this.tabCount > 0) {
        width = 100 / this.tabCount;
      }
      if (this.vertical) {
        width = 100;
      }
      return { width: `${width}%` };
    },
    activeTab() {
      return this.tabs[this.activeTabIndex];
    },
    stepPercentage() {
      return (1 / (this.tabCount * 2)) * 100;
    },
    progress() {
      let percentage = 0;
      if (this.activeTabIndex > 0) {
        let stepsToAdd = 1;
        let stepMultiplier = 2;
        percentage =
          this.stepPercentage * (this.activeTabIndex * stepMultiplier + stepsToAdd);
      } else {
        percentage = this.stepPercentage;
      }
      return percentage;
    },
    ...mapGetters(["updateVisibility"]),
    ...mapGetters(["updateStarted"]),
    select_disabled() {
      if (
        this.$store.getters.getSnUpdate == null ||
        this.activeTabIndex == 1 ||
        this.localStorageData.updateStarted === "waiting" ||
        this.localStorageData.updateStarted === "started"
      ) {
        return true;
      } else {
        return false;
      }
    },
    button_disabled() {
      if (
        (this.activeTabIndex == 1 && this.localStorageData.updateStarted === "started") ||
        this.localStorageData.updateStarted === "waiting"
      ) {
        return true;
      } else {
        return false;
      }
    },
    tableData() {
      const sns = this.$store.getters.sns;
      const oneOrTwoDigitDecimalPattern = /^\d{1,2}\.\d{1,2}$/;
      const data = sns
        .filter((sn) => sn.sn !== -1)
        .map((sn) => {
          return {
            SN: sn.sn,
            master: oneOrTwoDigitDecimalPattern.test(sn.master_version) ? sn.master_version : "-",
            gateway: oneOrTwoDigitDecimalPattern.test(sn.gateway_version) ? sn.gateway_version : "-",
            slave: oneOrTwoDigitDecimalPattern.test(sn.slave_version) ? sn.slave_version : "-",
          };
        });
      // console.log(data)
      return data;
    },
  },
  methods: {
    addTab(tab) {
      const index = this.$slots.default.indexOf(tab.$vnode);
      let tabTitle = tab.title || "";
      tab.tabId = `${tabTitle.replace(/ /g, "")}${index}`;
      if (!this.activeTab && index === 0) {
        tab.active = true;
        tab.checked = true;
      }
      if (this.activeTab === tab.name) {
        tab.active = true;
        tab.checked = true;
      }
      this.tabs.splice(index, 0, tab);
    },
    removeTab(tab) {
      const tabs = this.tabs;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
    validate(tab) {
      let tabToValidate = tab || this.activeTab;
      let beforeChange = tabToValidate.beforeChange;
      if (beforeChange) {
        return Promise.resolve(beforeChange())
          .then((res) => {
            this.activeTab.hasError = res ? false : true;
            return res;
          })
          .catch(() => {
            this.activeTab.hasError = true;
          });
      } else {
        return Promise.resolve(true);
      }
    },

    /*this is the function that will be called when the user clicks on the start button
      and will dispatch the update action to the server after the user confirms the update
      and will show a success message to the user after the update is done
    
    */
    async startUpdate() {
      this.selectedSn = this.$store.getters.getSnUpdate;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: this.$t("update.starttitel"),
          text: this.$t("update.starttext"),
          icon: "",
          showCancelButton: true,
          confirmButtonText: this.$t("update.confirmButtonText"),
          cancelButtonText: this.$t("update.cancelButtonText"),
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.$store.commit("setUpdateProgress", { key: this.selectedSn, value: 0 });
              const data = await this.$store.dispatch("sendUpdate");

              const updateCount = Object.values(data.updates).filter(
                (item) => item.new_update === true
              ).length;
              this.$store.commit("setAvalableUpdates", {
                key: this.selectedSn,
                value: updateCount,
              });
              if (updateCount > 1) {
                swalWithBootstrapButtons.fire({
                  title: "Info!",
                  html: this.$t("update.successMulti"),
                  icon: "info",
                });
              } else {
                swalWithBootstrapButtons.fire({
                  title: "ok!",
                  text: this.$t("update.successtext"),
                  icon: "success",
                });
              }
            } catch (error) {
              console.error("Error fetching update:", error);
            }
            // this.$store.commit('SET_UPDATE_VISIBILITY', 'visible');
            this.startPeriodicCheck();

            // console.log("start update");
            // swalWithBootstrapButtons.fire({
            //   title: "ok!",
            //   text: this.$t('update.successtext'),
            //   icon: "success"
            // });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire({
              title: this.$t("update.canceledtitel"),
              text: this.$t("update.canceledtext"),
              icon: "error",
            });
          }
        });
    },

    startPeriodicCheck() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });
      this.updateInterval = setInterval(() => {
        this.$store.dispatch("checkStillUpdating");
        this.selectedSn = this.$store.getters.getSnUpdate;
        let sn = this.selectedSn;
        // console.log("periodic check: ", sn);
        if (this.$store.getters.updateProgress[sn]) {
          this.updates_count = this.$store.getters.avalableUpdates[sn].avalableUpdates;
          // console.log("updateProgress: ", this.page, "updates_count: ", this.updates_count);
          this.page = this.$store.getters.updateProgress[sn].updateProgress;
          if (this.page > 99 && this.updates_count > 1) {
            swalWithBootstrapButtons.fire({
              title: "Done!",
              html: this.$t("update.oneOfMultiple"),
              icon: "success",
            });
            this.$store.commit("setAvalableUpdates", {
              key: this.selectedSn,
              value: this.updates_count - 1,
            });
          } else if (this.page == -1) {
            this.stopPeriodicCheck();
            swalWithBootstrapButtons
              .fire({
                title: "Error!",
                text: this.$t("update.interrupted"),
                icon: "error",
                reverseButtons: true,
              })
              .then((result) => {
                if (result.isConfirmed) {
                  this.$store.dispatch("updateSn", null);
                  this.$store.commit("setUpdateStarted", {
                    key: this.selectedSn,
                    value: null,
                  });
                  this.$store.commit("SET_UPDATE_VISIBILITY", "hidden");
                }
              });
          }
        }
        if (localStorage.getItem("updateStarted") !== null) {
          this.localStorageData = JSON.parse(localStorage.getItem("updateStarted"));

          if (this.localStorageData.updateStarted === "finished") {
            this.$store.commit("SET_UPDATE_VISIBILITY", "hidden");
            this.$store.dispatch("updateSn", null);
            this.stopPeriodicCheck();
          } else if (this.localStorageData.updateStarted === "started") {
            this.$store.commit("SET_UPDATE_VISIBILITY", "visible");
            this.activeTabIndex = 1;
          }
        } else if (this.$store.getters.updateStarted[sn].updateStarted === "finished") {
          this.$store.commit("SET_UPDATE_VISIBILITY", "hidden");
          this.$store.dispatch("updateSn", null);
          // console.log("periodic check: Hidden");
          this.stopPeriodicCheck();
        }
      }, 3000);
    },

    stopPeriodicCheck() {
      if (this.updateInterval) {
        clearInterval(this.updateInterval);
        this.updateInterval = null;
      }
    },

    async nextTab() {
      let isValid = await this.validate();
      if (isValid && this.activeTabIndex < this.tabCount - 1) {
        this.activeTabIndex++;
      }
      return isValid;
    },
    prevTab() {
      this.activeTabIndex--;
    },
    async navigateToTab(index) {
      if (this.tabs[index].checked) {
        // recursively validate each tab
        if (index > this.activeTabIndex) {
          let valid = await this.nextTab();
          if (valid) {
            this.navigateToTab(index);
          }
        } else {
          this.activeTabIndex = index;
        }
      }
    },
    onResize() {
      let tabLinks = document.getElementsByClassName("wizard-tab-link");
      if (tabLinks.length > 0 && this.tabCount > 0) {
        let { clientWidth, clientHeight } = tabLinks[0];
        this.tabLinkWidth = clientWidth;
        this.tabLinkHeight = clientHeight;
      }
    },
    refresh() {
      this.$store.dispatch("updateSn", null);
      this.$store.commit("setUpdateStarted", { key: this.selectedSn, value: null });
      window.location.reload();
    },
    cancel() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-success",
        },
        buttonsStyling: false,
      });
      if (
        this.localStorageData.updateStarted === "waiting" ||
        this.localStorageData.updateStarted === "started" ||
        this.updateVisibility === "visible"
      ) {
        swalWithBootstrapButtons
          .fire({
            title: this.$t("update.canceltitel"),
            html: this.$t("update.canceltext"),
            icon: "info",
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonText: this.$t("update.confirmCancel"),
            cancelButtonText: this.$t("update.denyCancel"),
          })
          .then((result) => {
            if (result.isConfirmed) {
              // console.log("cancel update, ", this.selectedSn);
              this.$store.dispatch("updateSn", null);
              this.$store.commit("setUpdateProgress", { key: this.selectedSn, value: null, });
              this.$store.commit("setUpdateStarted", { key: this.selectedSn, value: null, });
              this.$store.commit("SET_UPDATE_VISIBILITY", "hidden");
              localStorage.setItem("updateStarted", JSON.stringify({ sn: null, updateStarted: "" }));
              this.stopPeriodicCheck();
              this.prevTab();
              window.location.reload();
            }
          });
      } else {
        this.prevTab();
      }
    },
  },
  mounted() {
    this.$store.commit("resetSnUpdateList");
    this.$store.dispatch("checkSnUpdate");

    this.activeTabIndex = this.startIndex;
    if (!this.$store.getters.getAccessStatus) {
      this.$router.push("/dashboard");
    }
    this.$nextTick(() => {
      this.tabs[this.activeTabIndex].active = true;
      this.tabs[this.activeTabIndex].checked = true;
      this.onResize();
    });
    window.addEventListener(
      "resize",
      () => {
        throttle(this.onResize, 40);
      },
      false
    );
    if (this.$store.getters.updateVisibility == "visible") {
      this.activeTabIndex = 1;
    }
    if (this.$store.getters.getSnUpdate != null) {
      this.$store.dispatch("updateSn", this.$store.getters.getSnUpdate);
      this.selectedSn = this.$store.getters.getSnUpdate;
    }
    // this.$store.commit('setSnUpdate', null);
    // this.$store.commit('SET_UPDATE_VISIBILITY', 'hidden');
    // this.$store.dispatch('checkStillUpdating');
    if (this.$store.getters.getSnUpdateList.includes(this.$store.getters.getSnUpdate)) {
      this.selectedSn = this.$store.getters.getSnUpdate;
    } else {
      this.selectedSn = null;
      this.$store.commit("setSnUpdate", null);
    }

    if (localStorage.getItem("updateStarted") !== null) {
      this.localStorageData = JSON.parse(localStorage.getItem("updateStarted"));
      this.selectedSn = this.localStorageData.sn;
      if (
        this.localStorageData.updateStarted === "waiting" ||
        this.localStorageData.updateStarted === "started"
      ) {
        this.activeTabIndex = 1;
        if (!this.$store.getters.getSnUpdateList.includes(this.selectedSn)) {
          this.$store.commit("updateSnUpdateList", this.selectedSn);
        }

        if (this.localStorageData.updateStarted === "started") {
          this.$store.commit("SET_UPDATE_VISIBILITY", "visible");
          this.activeTabIndex = 1;
        }
      }
    } else {
      localStorage.setItem(
        "updateStarted",
        JSON.stringify({ sn: null, updateStarted: "" })
      );
    }
    // console.log("mounted: ", this.selectedSn, this.$store.getters.updateStarted);
    this.startPeriodicCheck();
    this.dataInterval = setInterval(() => {
      try {
        this.$store.dispatch("get_current_data", true);
      } catch (error) {
        try {
          if (this.$store.getters.getAccessStatus === 401) {
            console.log(error)
            console.log('Access token expired. Getting new access token...');
            this.$store.dispatch("getNewAccessToken");
          }
        }
        catch (error) {
          console.log('Error getting new access token:', error);
          alert('Error getting new access token:', error);
          window.location.href = '/logout';
        }
      }

    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
    this.stopPeriodicCheck();
    this.$store.dispatch("updateSn", null);
    this.$store.commit("setUpdateStarted", { key: this.selectedSn, value: null });
    this.$store.commit("SET_UPDATE_VISIBILITY", "hidden");
    clearInterval(this.dataInterval);
  },
  watch: {
    updateVisibility(newValue, oldValue) {
      let sn = this.selectedSn;
      if (newValue === "visible") {
        this.selectedSn = this.$store.getters.getSnUpdate;
        this.activeTabIndex = 1;
      } else if (localStorage.getItem("updateStarted") !== null) {
        if (this.localStorageData.updateStarted === "finished" && newValue == "hidden") {
          this.$store.commit("setSnUpdate", null);
          this.stopPeriodicCheck();
          this.nextTab();
        }
      }
    },
    updateStarted(newValue, oldValue) {
      let sn = this.$store.getters.getSnUpdate;
      // console.log("updateStarted_watcher: ", sn);
      if (newValue[sn].updateStarted != null) {
        if (newValue[sn].updateStarted === "waiting") {
        } else if (newValue[sn].updateStarted === "finished") {
          this.$store.commit("SET_UPDATE_VISIBILITY", "hidden");
          this.$store.commit("setSnUpdate", null);
          localStorage.setItem(
            "updateStarted",
            JSON.stringify({ sn: null, updateStarted: "" })
          );
          this.nextTab();
          this.stopPeriodicCheck();
        }
      }
    },
    activeTabIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        let oldTab = this.tabs[oldValue];
        let newTab = this.tabs[newValue];
        oldTab.active = false;
        newTab.active = true;

        if (!newTab.checked) {
          newTab.checked = true;
        }
        this.$emit("tab-change", oldTab, newTab);
        this.$emit("update:startIndex", newValue);
      }
    },
  },
};
</script>

<style lang="scss">
/* Tab content animation */
.tab-content {
  display: flex; // to avoid horizontal scroll when animating

  .tab-pane {
    display: block;
    animation: fadeIn 0.5s;
    width: 100%;
  }
}

/**
      Extra niceties. Display error tabs and disable navigation unvisited tabs
     */
.wizard-navigation .nav-link {

  &.active,
  &.checked {
    cursor: pointer;
  }
}

.disabled-wizard-link {
  cursor: not-allowed;
}

.wizard-container .progress {
  margin-top: 10px;
  margin-bottom: 30px;
}

.el-table table {
  margin-bottom: 0;
}

.table-transparent {
  background-color: transparent !important;
}
</style>