<template>
  <div>
    <!-- <h5 class="info-text">{{$t('update.step2header')}}</h5> -->
    <div class="row justify-content-center">
      <div v-if="!started && !waiting" class="col-lg-10 text-center">
        {{ $t("update.step2description") }}
      </div>
      <div v-if="!started && waiting" class="col-lg-10 text-center">
        {{ $t("update.waiting2min") }}
      </div>
      <div v-if="started" class="col-lg-10 text-center">
        <h3 style="margin: 0">Update
          <span :class="{ 'text-success': one_is_done }">
            {{ completedUpdates }}
          </span>
          / {{ totalUpdates }}
          <h5 v-if="one_is_done && completedUpdates == totalUpdates" class="text-success" style="margin: 0">
            {{ $t("update.finish") }}
          </h5>
          <h5 v-else-if="one_is_done" class="text-success" style="margin: 0" v-html="$t('update.oneOfMultiple')"></h5>
        </h3>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      initialAvailableUpdates: null,
      completedUpdates: 1,
      total: 0,
      one_is_done: false,
    };
  },
  computed: {
    sn() {
      // console.log("getSnUpdate", this.$store.getters.getSnUpdate);
      return this.$store.getters.getSnUpdate;
    },

    started() {
      // console.log("updateStarted", this.$store.getters.updateStarted);
      const updateStarted = this.$store.getters.updateStarted;
      return updateStarted[this.sn]?.updateStarted === "started";
    },
    waiting() {
      // console.log("updateStarted", this.$store.getters.updateStarted);
      // if (newValue[sn].updateStarted === "waiting") {
      //   console.log("waiting");
      //   const timeout = setTimeout(() => {
      //     Swal.fire({
      //       title: ' ',
      //       text: this.$t('update.waiting2min'),
      //       icon: 'error',
      //       confirmButtonText: this.$t("update.restart"),
      //       confirmButtonColor: "#285891",
      //     }).then(async (result) => {
      //       clearTimeout(timeout);
      //       if (result.isConfirmed) {
      //         clearInterval(this.updateInterval);
      //         this.stopPeriodicCheck();
      //         localStorage.setItem("updateStarted", JSON.stringify({ sn: null, updateStarted: "" }));
      //         this.$store.dispatch("updateSn", null);
      //         this.$store.commit("setUpdateStarted", { key: sn, value: null });
      //         this.$store.commit("SET_UPDATE_VISIBILITY", "hidden");
      //         window.location.reload();
      //       }
      //     });
      //   }, 60000);
      // }
      const updateStarted = this.$store.getters.updateStarted;
      return updateStarted[this.sn]?.updateStarted === "waiting";
    },

    totalUpdates() {
      // console.log("totalUpdates", this.$store.getters.totalUpdates[this.sn].totalUpdates);
      let totalUpdates_DB = this.$store.getters.totalUpdates[this.sn].totalUpdates;
      const updateProgress = this.$store.getters.updateProgress;

      if (updateProgress[this.sn].updateProgress > 99) {
        this.one_is_done = true;
      }

      if (totalUpdates_DB < 10) {
        this.total = totalUpdates_DB;
      } else if (this.one_is_done && updateProgress[this.sn].updateProgress == 100 && this.total !== this.completedUpdates) {
        this.completedUpdates = Math.floor(totalUpdates_DB / 10);
        this.total = totalUpdates_DB % 10;
      } else if (this.one_is_done && updateProgress[this.sn].updateProgress <= 2) {
        this.completedUpdates = Math.floor(totalUpdates_DB / 10) + 1;
        this.total = totalUpdates_DB % 10;
        this.one_is_done = false;
      } else {
        this.completedUpdates = Math.floor(totalUpdates_DB / 10) + 1;
        this.total = totalUpdates_DB % 10;
      }
      if (this.total < this.completedUpdates) {
        this.completedUpdates = this.total;
      }

      return this.total;
    },
  },
  methods: {
    validate() {
      this.$emit("on-validated", true, this.model);
      return Promise.resolve(true);
    },
  },
};
</script>
<style></style>
