<template>
  <div class="container">
    <div class="col-lg-4 col-md-6 ml-auto mr-auto">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submit)">
          <card class="card-login card-white">
            <template slot="header">
              <div class="header_container d-flex justify-content-between align-items-center">
                <div>
                  <img class="card-img  logo_sax_wrapper" src="img/card-info.png" alt="Card image" />
                  <img class="img-fluid logo_sax_login" src="img/saxlogwhitesmall.png" alt="Logo"
                    style="position: relative; z-index: 1; padding-top: 10px;padding-left: 5px;" />

                </div>
                <div>

                </div>

              </div>

            </template>

            <div>
              <ValidationProvider name="email" rules="email" mode="lazy" v-slot="{ passed, failed, errors }">
                <base-input :required="true" v-model="email" @input="email = email.trim()" type="text"
                  placeholder="Email" addon-left-icon="tim-icons icon-email-85" :error="errors[0]"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                </base-input>
              </ValidationProvider>

              <ValidationProvider name="password" rules="" v-slot="{ passed, failed, errors }">
                <base-input :required="true" v-model="password" placeholder="Password"
                  addon-left-icon="tim-icons icon-lock-circle" :error="errors[0]"
                  :type="passwordVisible ? 'text' : 'password'"
                  :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                  <template #addonRight>
                    <button type="button" class="toggle-password" @click="togglePasswordVisibility">
                      <i :class="passwordVisible ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                    </button>
                  </template>
                </base-input>
              </ValidationProvider>
            </div>
            <base-checkbox class="text-left" v-model="stayLogedIn">
              {{ $t('login.stay logged in') }}
            </base-checkbox>
            <div slot="footer">
              <base-button native-type="submit" type="info" class="mb-3" size="lg" block>
                {{ $t('login.login') }}
              </base-button>


              <div class="d-flex justify-content-between align-items-center">
                <h6>
                  <router-link class="link footer-link text-info" to="/register">
                    {{ $t('login.create') }} </router-link>
                </h6>
                <select style="color: black; background-color: white" class="form-select rounded"
                  aria-label="Default select example" v-model="selectedLanguage" @change="changeLanguage">
                  <option selected value="de">DE</option>
                  <option value="en">EN</option>
                  <option value="pl">PL</option>

                </select>
                <h6>
                  <router-link class="link footer-link text-info" to="/lock">
                    {{ $t('login.forgot') }} </router-link>
                </h6>
              </div>

            </div>
          </card>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
import { useAuth } from "../../modules/auth";
import { useApi } from "../../modules/api";
import { store } from "../../store/index";
import i18n from "@/i18n";
import { checkForServiceWorkerUpdate } from '../../registerServiceWorker';
extend("email", email);
extend("min", min);

extend("required", required);

export default {
  data() {
    return {
      // selectedLanguage: 'de',
      email: "",
      password: "",
      passwordVisible: false,
      stayLogedIn: false
    };
  },
  created() {
    if (localStorage.getItem("languageSelected")) {
      this.selectedLanguage = localStorage.getItem("languageSelected");
    } else {
      this.selectedLanguage = "de";
    }
    this.changeLanguage();
    this.$store.commit('resetGeneralStore');
    let docClasses = document.body.classList;
    docClasses.remove('white-content');
  },
  beforeRouteLeave(to, from, next) {
    document.body.classList.remove("login");
    
    next();
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    changeLanguage() {
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem("languageSelected", this.selectedLanguage);
    },
    
    async submit() {
      try {
        let response = await useApi.post(
          "/api/auth/token/",
          {
            email: this.email,
            password: this.password,
            stayLoggedIn: this.stayLogedIn,
          },
          {
            withCredentials: true,
          }
        );
        
        if (response.status >= 200 && response.status < 300) {
          if (window.PasswordCredential) {
            const passwordCredential = new window.PasswordCredential({
              id: this.email,
              password: this.password,
            });
            navigator.credentials.store(passwordCredential);
          }
          
          useAuth.setUser(response); // set the user
          await store.dispatch("get_sns");
          await store.dispatch("get_current_data");
          this.$router.push("/dashboard");
          checkForServiceWorkerUpdate();
        } else {
          throw new Error('Login failed with status: ' + response.status);
        }
        
      } catch (error) {
        Swal.fire({
          title: i18n.t('login.failedTitle'),
          text: i18n.t('login.failedText'),
          icon: 'error',
          confirmButtonText: 'OK',
          confirmButtonColor: "#285891",
        });
      }
    }

  }

};
</script>
<style>
.toggle-password {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  color: inherit;
}

.toggle-password i {
  font-size: 1.2rem;
}

.input-group:not(.has-validation)>.form-control:not(:last-child) {
  border-top-right-radius: 6.856px;
  border-bottom-right-radius: 6.856px;
  border-right-style: solid;
  border-right-width: 1px;
}

.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

@media (max-width: 767.98px) {
  .logo_sax_wrapper {
    width: 70%;
  }
}

@media (max-width: 576px) {
  .logo_sax_login {
    width: 120px;

  }
}

@media (min-width: 576px) {
  @media (max-width: 992px) {
    .logo_sax_login {
      width: 150px;
      /* Adjust the width for medium screens */
    }
  }
}

@media (min-width: 992px) {
  .logo_sax_login {
    width: 200px;
    /* Adjust the width for large screens */
  }

}
</style>