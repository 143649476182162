<template>
    <div
      class="loadoverlay"
      :style="'background-color: rgba(255,255,255,'+opacity+')'"
    >
      <div class="loader" />
      <div v-if="text" class="text-center">{{ text }}</div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadOverlay',
    props: {
      opacity: {
        type: Number,
        default: .5
      },
      text: {
        type: String,
        default: ''
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  @import "src/assets/scss/variables";
  
  .loadoverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    z-index: 1000;
  }
  
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
  }
  
  .loader {
    position: relative;
    text-indent: -9999em;
    border-top: 3px solid rgba($primary, .2);
    border-right: 3px solid rgba($primary, .2);
    border-bottom: 3px solid rgba($primary, .2);
    border-left: 3px solid $primary;
    transform: translateZ(0);
    animation: load8 1.1s infinite linear;
  }
  .text {
    color: #285891;
    margin-top: 1.5rem;
    font-size: 1rem;
    text-align: center;
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  </style>
  